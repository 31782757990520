.header-links-container {
  display: flex;
  align-items: center;
}

.header-links {
  background-color: white;
  padding: 10px 20px; /* Match the padding with toggle-button */
  border-radius: 6px;
  margin-left: 12px;
  font-size: 18px;
  transition-property: all;
  transition-duration: 0.2s;
  display: flex;
  align-items: center; /* Ensure vertical centering */
}

.header-links:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.toggle-label {
  margin-right: 10px;
  font-size: 20px;
}

.toggle-button {
  padding: 10px 20px; /* Match the padding with header-links */
  border: 2px solid #000;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  background-color: white;
  color: black;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  display: flex;
  align-items: center; /* Ensure vertical centering */
}

.toggle-button:hover {
  background-color: #f0f0f0;
  border-color: #007BFF;
  color: #007BFF;
}

.banner {
  background-color: #C6D5DA;
  padding: 20px;
  padding-bottom: 40px;
  clip-path: polygon(
    0 0, 
    100% 0, 
    100% calc(100% - 10px), 
    75% 100%, 
    50% calc(100% - 10px), 
    25% 100%, 
    0 calc(100% - 10px)
  );
  text-align: center; /* Center the text */
}

.bio {
  width: 100%;
  color: #276567;
  padding: 12px;
  text-align: center; /* Center the text */
}

.bio > h3 {
  color: #CDC5DA;
  font-size: 24px;
  text-align: center; /* Center the text */
}
