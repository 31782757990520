/* Mobile-first styles (default for smaller screens) */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  text-align: left;
}

@media (min-width: 768px) {
  p {
    text-align: center
  }
}

.header-container {
  background-color: #C6D5DA;
}

header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #C6D5DA;
  max-width: 1250px;
  margin: 0 auto;
}

/* .container {
  max-width: 1250px;
  margin: 0 auto;
  display: flex;
} */

.logo {
  background-color: white;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-links-container {
  display: flex;
}

.header-links {
  background-color: white;
  padding: 4px 12px;
  border-radius: 6px;
  margin-left: 12px;
  font-size: 20px;
  transition-property: all;
  transition-duration: .2s;
}

.header-links:hover {
  /* font-size: 22px; */
  /* padding: 4.5px 13px; */
  background-color: black;
  color: white;
  cursor: pointer;
}

/* .header-links:first-of-type {
  text-decoration: underline;
} */

.banner {
  background-color: #C6D5DA;
  padding: 20px;
  padding-bottom: 40px;
  clip-path: polygon(
    0 0, 
    100% 0, 
    100% calc(100% - 10px), 
    75% 100%, 
    50% calc(100% - 10px), 
    25% 100%, 
    0 calc(100% - 10px)
  );
}

.license {
  color: white;
}

.about-me {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 40px;
  max-width: 1250px;
  margin: 0 auto;
}

.image {
  width: 100%;
  height: auto;
}

.image > img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.bio {
  width: 100%;
  color: #276567;
  padding: 12px;
}

.bio > h3 {
  color: #CDC5DA;
  font-size: 24px;
}



p {
  font-size: 22px;
  line-height: 1.4;
}

.insurance-section {
  padding-top: 40px;
  background-color: #C6D5DA;
  clip-path: polygon(
    0 10px, /* Start at 10px down from the top-left corner */
    25% 0, /* Go to the top, 25% from the left */
    50% 10px, /* Down 10px, 50% from the left */
    75% 0, /* Back to the top, 75% from the left */
    100% 10px, /* Down 10px at the top-right corner */
    100% 100%, /* Down to the bottom-right corner */
    0 100% /* And to the bottom-left corner */
  );
}

.insurance-plans {
  display: flex;
  justify-content: space-around;
}

ul {
  list-style-type: none;
}

/* Styles for larger screens (tablets, desktops) */
@media (min-width: 769px) {
  .about-me {
    flex-direction: row;
    align-items: stretch;
  }

  .image, .bio {
    width: 50%;
  }

  .image > img {
    height: 100%; /* Adjusts the image height to match the container */
  }
}

.contact-section-container {
  background-color: #C6D5DA;

}

.contact-section {
  padding: 20px;
  max-width: 1260px;
  margin: 0 auto;
}

.contact-section > div {
  /* width: 50%; */
  padding: 20px;
}

.contact-section h3 {
  font-size: 20px;
}

.contact-section p {
  font-size: 26px;
  text-align: left;
}

@media (min-width: 769px) {
  .contact-section {
    display: flex;
  }
  .contact-section > div {
    width: 50%;
    padding: 20px;
  }
  
  .contact-section h3 {
    font-size: 28px;
  }
  
  .contact-section p {
    font-size: 20px;
    text-align: left;
  }
}





.contact-form-container {
  /* padding: 20px; */
  /* margin-top: 40px; */
  /* background-color: #f0f0f0; */
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  /* display: flex; */
  /* flex-direction: column; */

}

.form-label {
  text-align: left;
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  /* color: #276567; */

}

.form-input, .form-textarea, .form-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #c6d5da;
  border-radius: 4px;
  font-size: 16px;
}

.form-input:focus, .form-textarea:focus, .form-select:focus {
  outline: none;
  border-color: #276567;
}

.form-textarea {
  height: 100px;
  resize: vertical;
}

.form-button {
  padding: 10px 15px;
  background-color: #276567;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.form-button:hover {
  background-color: #204d4a;
}


.faq-container {
  background-color: #f0f0f0;
}

.faq-accordion {
  max-width: 1250px;
  margin: 20px auto;
  padding: 20px;
  
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
}

.faq-top-bar, .faq-bottom-bar {
  height: 2px;
  background-color: #C6D5DA;
  margin: 10px 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  font-size: 20px;
}

.faq-icon {
  margin-left: 10px;
}

.faq-icon.active {
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.faq-answer {
  display: none;
  padding: 10px 0;
  font-size: 18px;
  line-height: 1.4;
  color: #276567;
}

.faq-answer.show {
  display: block;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .faq-question {
    font-size: 18px;
  }

  .faq-answer {
    font-size: 16px;
  }
}